import Configuration from "@/managers/system/configuration"
import Notifications from "@/managers/session/notifications"

enum ScriptState {
	initial,
	timeout,
	error,
	ready
}

const loader = new Promise<IFacebook>((resolve, reject) => {
	let state = ScriptState.initial
	let timeout: number | undefined

	// @ts-ignore
	window.fbAsyncInit = () => {
		const shouldResolve = state === ScriptState.initial
		if (shouldResolve)
			state = ScriptState.ready
		else
			Notifications.error("Facebook SDK loaded after state was: " + state)

		window.clearTimeout(timeout)
		FB.init({
			appId: Configuration.connections.facebookAppId,
			version: "v21.0",
			status: false,
			xfbml: false,
			localStorage: true
		})
		if (shouldResolve)
			resolve(FB)
	}

	const script = document.createElement("script")
	script.src = "https://connect.facebook.net/en_US/sdk.js"
	script.async = true
	script.addEventListener("load", () => {
		if (state !== ScriptState.initial)
			return

		timeout = window.setTimeout(() => {
			if (state !== ScriptState.initial)
				return
			state = ScriptState.timeout
			reject(new Error("Facebook SDK timed out"))
		}, Configuration.connections.facebookSdkTimeout)
	})
	script.addEventListener("error", event => {
		event.preventDefault()
		if (event?.error)
			Notifications.warning(`Failed to load Facebook: ${event?.error?.toString() ?? "Unknown error"}`)

		if (state !== ScriptState.initial)
			return

		state = ScriptState.error
		window.clearTimeout(timeout)
		reject(new Error("Failed to load Facebook SDK"))
	})
	document.head!.appendChild(script)
})

export function call<T>(path: string, parameters: object): Promise<T> {
	return loader.then(facebook => {
		return new Promise<T>((resolve, reject) => {
			try {
				facebook.api(path, parameters, (response: T | undefined | IFacebookError) => {
					if (!response)
						reject(new Error("No response from Facebook on " + path))
					else if ((response as IFacebookError).error)
						reject(new Error("Facebook call " + path + " failed: " + (response as IFacebookError).error.message))
					else
						resolve(response as T)
				})
			} catch (error: any) {
				reject(new Error("Failed to call Facebook: " + (error.message ?? error.toString())))
			}
		})
	})
}

interface IFacebookError {
	error: {
		message: string
	}
}

export default loader
